import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import PageHeading from '../components/PageHeading'
import { Link } from "gatsby"
import "../assets/sass/style.scss"

const NotFoundPage = () => {
    
    return (
        <Layout>
            <SEO title="404 Page Not Found" />
            
            <PageHeading text="404 – Page Not Found">
                <p>
                    Sorry, we can't locate that page. Please try the&nbsp;
                    <Link
                        to="/"
                        className="brand">
                        homepage
                    </Link>
                  .
                </p>
            </PageHeading>

        </Layout>
    )
}

export default NotFoundPage